import {
  CheckIsPasswordConfiguredRequest,
  CheckIsPasswordConfiguredResponse,
  CreateUserRequest,
  CreateUserResponse,
  GetUserByAuthTokenRequest,
  GetUserRequest,
  GetUserResponse,
  GetUserResponseByToken,
  GetUsersRequest,
  GetUsersResponse,
  PaginateUsersRequest,
  PaginateUsersResponse,
  PatchMyInfoRequest,
  PatchMyInfoResponse,
  ResetUserPasswordRequest,
  ResetUserPasswordResponse,
} from "./User.dto";
import { UserUrl } from "./User.url";
import { restReq } from "../common/cyan.types";
import { FxApiRequestData, FxRequestData } from "../common/flax.types";
import { extractKey } from "../common/HttpRequest";

// Rest

export class UserFlax {
  public static getUserById(data: FxRequestData<GetUserRequest>): FxApiRequestData<GetUserResponse> {
    const userId = extractKey(data, "userId");

    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.getUserById(userId), data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static getMyInfo(data: FxRequestData<GetUserByAuthTokenRequest>): FxApiRequestData<GetUserResponseByToken> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.getMyInfo, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  // Deprecated (2023.04.12)
  public static patchMyInfo(data: FxRequestData<PatchMyInfoRequest>): FxApiRequestData<PatchMyInfoResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.patchMyInfo, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static getUsers(data: FxRequestData<GetUsersRequest>): FxApiRequestData<GetUsersResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.getUsers, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static paginateUsers(data: FxRequestData<PaginateUsersRequest>): FxApiRequestData<PaginateUsersResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.paginateUsers, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static createUser(data: FxRequestData<CreateUserRequest>): FxApiRequestData<CreateUserResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.createUser, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static resetUserPassword(data: FxRequestData<ResetUserPasswordRequest>): FxApiRequestData<ResetUserPasswordResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.resetUserPassword, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }

  public static checkIsPasswordConfigured(
    data: FxRequestData<CheckIsPasswordConfiguredRequest>
  ): FxApiRequestData<CheckIsPasswordConfiguredResponse> {
    return {
      ...restReq(process.env.REACT_APP_SSO_BE_URL, UserUrl.checkIsPasswordConfigured, data),
      reducer: resp => resp,
      errReducer: resp => resp,
    };
  }
}
